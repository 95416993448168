import { Section } from "@/components/layout/Section";
import { Image, Link } from "@chakra-ui/next-js";
import { Box, Flex, Grid, Heading, Text } from "@chakra-ui/react";
import ABOUT_US_IMG from "@/img/home_visionary_meals.jpg";

export const AboutUsBrief: React.FC = () => {
  return (
    <Section
      padded
      background="linear-gradient(180deg, #EFEFF1 0%, #FDFEFF 100%)"
    >
      <Grid gridTemplateColumns={{ base: "1fr", lg: "1fr 1fr" }} rowGap="8">
        <Box>
          <Image
            src={ABOUT_US_IMG}
            alt="Picture of owner Josh Link and nutritionist Casey Lewandowski"
            w="100%"
            aspectRatio={{
              base: "1.5",
              lg: "1",
            }}
            objectFit="cover"
          />
        </Box>
        <Flex
          px={{
            base: "4",
            lg: "12",
          }}
          flexDir="column"
          justifyContent="center"
          alignItems={{ base: "center", lg: "flex-start" }}
          rowGap="8"
          textAlign={{ base: "center", lg: "left" }}
        >
          <Heading fontWeight={{ base: "semibold", lg: "regular" }}>
            Visionary Meals
          </Heading>
          <Text>
            We, at Visionary Meals, don&apos;t just bring you a meal, but a
            feeling. We are here because we understand the growing desire to eat
            healthy, but we also understand that the fast-paced society we live
            in simply isn&apos;t slowing down.
          </Text>
          <Text>
            But what truly separates us from other meal prep companies?
            Visionary has been built on love, compassion, empathy, and grace.
            Being a faith-based company, the mission behind the brand has always
            far surpassed the act of meal prepping. Every day, the mission of
            this company is to not just offer the best product on the market,
            but to leave a generational impact for our employees and a top-tier
            experience for our customers.
          </Text>
          <Link
            href="/our-story"
            bg="#8DEBCF"
            py="4"
            px="8"
            display="block"
            w="fit-content"
            minW="0"
            rounded="full"
          >
            Read our Story
          </Link>
        </Flex>
      </Grid>
    </Section>
  );
};
