import { Hero } from "@/components/layout/Hero";
import { Section } from "@/components/layout/Section";
import React from "react";

export const AffiliateSignUpSection: React.FC = () => {
  return (
    <Section background="linear-gradient(90deg, #8DEBCF 0%, #FDFEFF 100%)">
      <Hero
        header="Interested in becoming an Affiliate?"
        tagline="Team up with Visionary Meals, the premier meal delivery service. Help us expand our vision, from our community to yours!"
        buttons={[
          {
            label: "Apply Now",
            onClick: () => {
              window.open("https://forms.office.com/r/e3Pnv94UV8", "_blank");
            },
            bg: "#FDFEFF",
          },
        ]}
      />
    </Section>
  );
};
